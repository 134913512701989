<template lang="html">

  <section class="organizations">
    <hero-global img="/img/hero/organitions.svg" title="Organizaciones" />
    <div class="container menu-anclas-responsive" >
        <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="select-anclas-responsive submenu m-top-0 m-bottom-20-px" v-model="menuAnclas" :activeMenuTop="menuTopAnclas">
          <option v-for="(cat, key) in $tm('translations.catOrganizations')" :key="key" :value="key">
            {{cat.name}}
          </option>
        </select>
      </div>
    <div class="box-content organizacion container">
      <ul class="menu-list container">
        <li v-for="(cat, key) in $tm('translations.catOrganizations')" :key="key" :value="key">
          <router-link :class="[idParams == cat.id ? 'router-link-active' : '']" :to="{ 
              name: 'organizaciones',
              params: {
                slug: key,
                id: cat.id
              } }" title="Ver {{cat}}" alt="Ver {{cat}}">
            {{cat.name}}
          </router-link>
        </li>
      </ul>
   
      <div class="content container">
        <b class="title-decoration-bottom font-family-frank-ruhl ">{{$t('translations.catOrganizations.'+slug+'.name')}}</b>
        <ul class="list-item" v-if="dataOrganizations != 0 && dataOrganizations">
          <li v-for="(item, key ) in dataOrganizations" :key="key">
            <input type="checkbox" name="list" :id="item.dataId" />
            <label :for="item.dataId">
              <img :src="item.logo.url" :alt="'Imagen de '+item.name" v-if="item.logo.url">
              <span> {{item.name}}</span>
            </label>
            <div class="inner-container">
              <p v-if="item.address" class="category-location item-list">{{item.address}}</p>

              <div class="category-telephone item-list" v-if="item.phone">
                <span>
                  Teléfono: <a :href="'tel:'+item.phone" :title="'Llamar a '+item.phone">{{item.phone}}</a>
                </span>
                <span v-if="item.fax">
                  Fax: {{item.fax}}
                </span>
              </div>
              <a v-if="item.web" class="category-web item-list" :href="item.web" target="_blank" :title="'Ir al '+item.web">{{item.web}}</a>
              <a v-if="item.email" :href="'mailto: '+item.email" class="category-email item-list" :title="'Mandar un emali a '+item.email">{{item.email}}</a>
            </div>
          </li>

        </ul>
        <template v-else>
          <p class="subtitle">Actualmente no hay resultados</p>
        </template>
      </div>
    </div>
    <carrousel-2 class="container m-bottom-40-px" :dataContent="$tm('translations.carrouselData.cermi')" />
    <other-links-interest class="links-interest-box container" />
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import { contentStore } from '@/stores/contents'
  import { i18n } from '@/i18n'
  export default {
    name: 'organizations',
    props: ['id', 'slug'],
    components: {
      OtherLinksInterest,
      HeroGlobal,
      Carrousel2
    },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      this.store.loadOrganizations({ catId: this.idParams })
      this.topMenuAnclas()
    },
    data() {
      return {
        mostrar: false,
        menuAnclas: this.slug,
         menuTopAnclas: false,
      }
    },
    methods: {
      toggleShowSubMenuResponsive: function (event) {
        if (this.mostrar == true) {
          this.mostrar = false;
        } else {
          this.mostrar = true;
        }

      },
      topMenuAnclas: function () {
        window.onscroll = () => {
          if (window.scrollY > 0) {
            this.menuTopAnclas = true;
          } else {
            this.menuTopAnclas = false
          }
        }
      },
    },
    computed: {
      dataOrganizations() {
        if (this.store.organizations[this.idParams]) {
          return this.store.organizations[this.idParams].items
        }
        return null
      },
      idParams() {
        if (this.id) {
          return this.id
        } else {
          return this.$t('translations.catOrganizations.' + this.slug + '.id')
        }

      }
    },
    watch: {
      slug() {
        this.store.loadOrganizations({ catId: this.idParams })
      },
      "menuAnclas"() {
         this.$router.push({
          name: 'organizaciones',
          params: {
            slug: this.menuAnclas,
          }
        });
      }
    }
  }


</script>

<style scoped lang="scss">
  .organizations {
    .hero-content {
      height: 320px;

    }
  }
</style>